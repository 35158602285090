.checkboxGroup {
}

.error {
}

.Label {
}

.SubLabel {
}

.optionsContainer {
	padding-top: 15px;
	display: flex;
	flex-wrap: wrap;
	padding-left: 25px;
}

.optionsContainer.fit li {
	flex: 1 1;
	min-width: 33%;
}

.optionsContainer li {
	margin-bottom: 10px;
}

.fullWidth {
	width: 100%;
}

.break {
	flex-basis: 100%;
	width: 0;
}

.base {
}

.gray {
	padding: 0;
	padding-top: 5px;
	margin-left: -5px;
}

.btn {
	padding-left: 0;
	padding-right: 0;
}

.btn.vertical {
	align-items: center;
}

.btn.horizontal {
	justify-content: center;
}

.btn li {
	margin: 0 5px;
}

.vertical {
	flex-direction: column;
}

.horizontal {
	flex-direction: row;
}

.checkboxBtn {
	border-radius: 10px;
	border: 1px solid #dadce0;
	width: 190px;
	min-height: 150px;
	padding: 10px;
	margin-bottom: 20px;
}

.checkboxBtn.checked {
	border: 1px solid #f26122;
}

.checkboxBtn:hover {
	cursor: pointer;
}

.checkboxBtnIconContainer {
	text-align: center;
}

.checkboxBtnIcon {
	width: 40px;
	height: 40px;
}

.checkboxBtnLabelContainer {
	padding-top: 5px;
	width: 100%;
}

.checkboxBtnLabel {
	font-size: 14px;
	color: #3b3b3b;
	text-align: center;
	width: 100%;
	display: block;
}

.checkboxBtnSubLabel {
	font-size: 12px;
	color: #3b3b3b;
	text-align: center;
}

.checkboxBtnOptionsContainer {
	color: #3b3b3b;
	display: flex;
	padding-top: 5px;
	justify-content: center;
	flex-wrap: wrap;
}

.checkboxBtnOptionsContainerBlock {
	color: #3b3b3b;
	display: block;
	padding-top: 5px;
}

.checkboxBtnOptionsContainer li {
	margin: 0;
}

.checkboxBtnOptionsContainer :global(label),
.checkboxBtnOptionsContainer :global(.slds-form-element__label) {
	font-size: 10px !important;
}

.checkbox {
}

.checked :global(.slds-checkbox_faux::after) {
	border-bottom: 2px solid #f26122 !important;
	border-left: 2px solid #f26122 !important;
}

:root {
	--custom-checkbox-color: #8b9095;
}

.checkbox.disabled.checked
	input:disabled
	+ label
	span[class*='slds-checkbox_faux'] {
	background-color: var(--custom-checkbox-color) !important;
}
