.th {
	display: flex;
	align-items: center;
	padding: 1.5vh 0.6vw;
	color: white;
	height: 100%;
	background-color: var(--blue);
	font-family: var(--common-font);
	font-size: min(0.8vw, 14px);
}

.th div:nth-child(1) {
	margin-right: 0.5em;
}

.th.sortable {
	cursor: pointer;
	padding-right: 0 !important;
}

.td {
	padding: 0.7em 0.6em;
	font-family: var(--common-font);
	font-size: min(0.8vw, 14px);
	/* display: flex; */
	align-items: center;
	word-wrap: break-word;
}

.sortIcon {
	margin-left: 0.1em;
}

.tr {
	align-items: stretch;
}

.tr .td:first-child {
	border-left: 1px solid #dadfe2;
}

.tr .td:last-child {
	border-right: 1px solid #dadfe2;
}

.tr:last-child .td:first-child {
	border-bottom-left-radius: 10px;
}

.tr:last-child .td:last-child {
	border-bottom-right-radius: 10px;
	padding-bottom: 30px;
}

.trEvenColumn:nth-child(even) .td {
	background: #f3faff;
}
* .tr .td {
	border-bottom: 1px solid #dadfe2;
}

.tbody {
	height: 50vh;
}

.statusPending {
	color: #f26122;
}
.statusApproved {
	color: #14bb02;
}
.statusDeclined {
	color: #d93025;
}

.paperContainer {
	padding: 1.5vmax;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
}

.customPaperContainer {
	padding: 0.5vmax 0;
}

.pagination {
	margin-top: 2vh;
}

.pagination > div {
	height: 34px;
	padding: 0;
}

.limitContainer {
	margin-right: 1vw;
}

.pageOptions {
	font-family: var(--common-font);
}

.pageOptions button,
.pageOptions button:not(button:disabled):hover {
	color: var(--orange);
}

.pageOptions button:hover {
	background-color: #f3f3f3;
}

.pageOptions .pageActive {
	background-color: var(--orange);
	color: white;
	cursor: default;
	pointer-events: none;
}

.emptyRow {
	width: 100%;
	height: 3.5vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: min(0.8vw, 14px);
	font-family: var(--common-font);
	border-left: 1px solid #dadfe2;
	border-right: 1px solid #dadfe2;
}

.emptyRow:last-child {
	border-bottom: 1px solid #dadfe2;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.paginationContainer {
	font-family: var(--common-font);
	font-size: var(--input-label-font-size);
}

.pageBtn {
	line-height: 0 !important;
}

.sortIcon {
	margin-left: 0.1vw;
}

.noResult {
	width: 100%;
	margin: 12px;
	text-align: center;
	display: inline-block;
}

.thead div[role='columnheader']:first-child div {
	border-top-left-radius: 7px;
}

.thead div[role='columnheader']:last-child div {
	border-top-right-radius: 7px;
}

.withPreHeader div[role='columnheader']:first-child div {
	border-top-left-radius: 0;
}

.withPreHeader div[role='columnheader']:last-child div {
	border-top-right-radius: 0;
}

.preHeader {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	background-color: var(--blue);
	display: flex;
	justify-content: flex-end;
	width: 100%;
	height: auto;
	padding: 10px 16px;
}

.trHeightForFields {
	height: 15vh;
}

.custom_tr.tr {
	display: flex;
	/* flex: 1 0 auto; */
	min-width: 0px;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.loadMoreBtn {
	position: fixed;
	width: 100%;
	text-align: center;
	color: #f26122;
	font-weight: 700;
	cursor: pointer;
	font-size: 14px;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}
