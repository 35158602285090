.isActive a,
.isActive {
	box-shadow: inset 0.25rem 0 0 var(--orange);
	color: var(--orange) !important;
}
.isActiveSubMenuLinks {
	color: var(--orange) !important;
}

.link {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
	padding: 0.4rem 1.5rem 0.4rem 2rem;
	color: #080707;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-radius: 0;
	cursor: pointer;
	text-decoration: none !important;
	font-size: 0.7vw;
	font-family: var(--common-font-bold);
	letter-spacing: 0.21px;
}

.chevron {
	fill: black;
}

.submenus {
	padding-left: 1vw;
}

.submenu {
	font-size: 0.8vw;
}

.submenuActive {
	color: var(--orange) !important;
}

.submenuActive:hover {
	color: var(--whiteOrange) !important;
}

.navListContainer {
	min-height: 50vh;
}

.menuLink {
	font-size: 0.8vw;
}

.submenulinks {
	font-size: 1vw;
}

.submenulinksname {
	font-size: 0.8vw;
}
