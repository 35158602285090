.loadMoreBtn {
	margin-top: 1vh;
	border: none !important;
	width: 100%;
	color: var(--orange) !important;
}

.loadMoreBtn:focus,
.loadMoreBtn:active {
	background-color: #ececec !important;
}

.loadMoreBtn:hover {
	background-color: #fdfdfd !important;
}
