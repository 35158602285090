.modal {
	width: 30% !important;
	min-width: 0 !important;
}

.headerText {
	font-family: var(--common-font);
	font-size: 1vw;
	font-weight: 500;
	letter-spacing: 0px;
	color: #1e1e1e;
}

.bodyText {
	font-family: var(--common-font);
	font-size: 0.9vw;
	letter-spacing: 0;
	color: #3e3f42;
}

.apiBatchTableBody tr:nth-child(odd) {
	background: #f3faff;
	border-bottom: 1px solid #dadfe2;
	border-top: 1px solid #dadfe2;
}

.apiBatchTable th {
	background-color: white !important;
}

.successIcon path {
	fill: #28a745;
}

.failedIcon path {
	fill: #d93025;
}

.svg svg {
	transform: scale(0.4);
}
