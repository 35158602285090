.innerButtons {
	padding-top: 2.2em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.rowBreak {
	word-break: break-all;
}

.downloadDropdown {
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 2%;
	top: 12%;
}

.downloadContainer {
	position: relative;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}
